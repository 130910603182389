//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

$lhcolwidth: 200px;

.logo {
  letter-spacing: 0.035em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  font-family: "Metric";
  font-size: 17px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: rgba($color: #000000, $alpha: 1);
  font-weight: 700;
  padding-left: 25px;
  .grey {
    color: #9d9d9d;
  }
}

.workstage-list {
  cursor: pointer;
  td {
    padding: 1rem;
  }
  .workstage__icon {
    @include kt-btn-reset();
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: kt-base-color(grey, 1);
    transition: all 0.3s ease;
    cursor: pointer;
    margin: 0;
    border-radius: 0;

    i {
      font-size: 1.1rem;
    }

    &.workstage__icon--sm {
      height: 26px;
      width: 26px;

      i {
        font-size: 0.8rem;
      }
    }

    &.workstage__icon--md {
      height: 30px;
      width: 30px;

      i {
        font-size: 1rem;
      }
    }

    &.workstage__icon--light {
      background-color: transparent;
    }

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }

    i {
      color: $kt-inbox-icon-color;
    }

    @include kt-svg-icon-color($kt-inbox-icon-color);

    &.workstage__icon--active,
    &:hover {
      transition: all 0.3s ease;
      background-color: kt-base-color(grey, 2);

      &.workstage__icon--light {
        background-color: transparent;
      }

      i {
        color: kt-brand-color();
      }

      @include kt-svg-icon-color(kt-brand-color());
    }

    // Back
    &.workstage__icon--back {
      background-color: transparent;

      i {
        color: $kt-inbox-icon-color;
        font-size: 1.5rem;
      }

      @include kt-svg-icon-color($kt-inbox-icon-color);

      svg {
        height: 32px;
        width: 32px;
      }

      &:hover {
        background-color: transparent;

        i {
          color: kt-brand-color();
        }

        @include kt-svg-icon-color(kt-brand-color());
      }
    }
  }
}

.workstages-modal {
  width: 90vw;
  max-width: 90vw;
}
@include kt-tablet-and-mobile {
  .workstages-modal {
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 100vw;
  }
}
.workstages {
  margin: 1rem;
  padding: 1rem;

  .budget-value {
    color: #48465b;
    font-weight: 600;
    font-size: 1.2rem;

    span {
      color: #74788d;
    }
  }
  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}

.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
}
.SelectedWeekExample .DayPicker-WeekNumber {
  outline: none;
}
.SelectedWeekExample .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.SelectedWeekExample .DayPicker-Day--hoverRange {
  background-color: kt-brand-color() !important;
  color: white;
  border-radius: 0 !important;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background-color: kt-brand-color() !important;
  color: white;
}

.SelectedWeekExample
  .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample
  .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: white !important;
}
.SelectedWeekExample .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}

.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}

@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}

.totals-fixed-sentinel {
  min-height: 50px;
}
.timesheet {
  padding: 25px;

  .padded {
    padding-left: $lhcolwidth;
    &.totals-fixed {
      position: fixed;
      background-color: rgba($color: #fff, $alpha: 0.6);
      pointer-events: none;
      margin-top: -100px;
    }

    &.totals-fixed-top-more {
      margin-top: -195px;
    }
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(7, minmax(60px, 120px));
    grid-template-rows: 50px;
    // grid-column-gap: 10px;
    // grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
  }
  .grid-row {
    grid-row: 1;
    &.grid-row--block span {
      display: block;
    }

    span.grid-row--button {
      width: 90px;
    }

    // &:nth-child(6),
    // &:nth-child(7) {
    //   background-color: kt-base-color(grey, 1);
    // }
  }

  .kt-section__title {
    margin: 0 0 0.75rem 0;
    font-size: 1rem;

    button {
      color: kt-base-color(grey, 1);
      &:hover {
        background-color: transparent;
      }
    }
    // a {
    //   color: kt-base-color(grey, 3);
    //   &:hover {
    //     color: kt-base-color(grey, 4);
    //   }
    // }
    .kt-badge {
      position: relative;
      top: -2px;
    }
  }
  .kt-separator {
    margin: 10px 0;
  }
  &.kt-portlet--sticky {
    .kt-portlet__head {
      min-height: auto !important;
      width: 80%;
      box-shadow: none;
    }
    .timesheet__headerrow {
      margin-left: $lhcolwidth;
      padding-left: 0;
    }
  }
  .kt-portlet__head {
    min-width: auto !important;
    border-bottom: 0 !important;
    padding: 0;
  }
  .timesheet__headerrow {
    // padding-left: 250px;
    margin-bottom: 0;
    display: flex;
    width: 65%;
    margin-left: $lhcolwidth;

    div {
      //flex: 0 0 auto;

      // padding: 0 0.25rem;
      flex-grow: 1;
      span {
        display: block;
        // width: 70px;
        // max-width: 60px;
      }
    }
  }
  .kt-portlet__body {
    padding: 0px !important;
    //margin: 25px;
  }
}

.timesheet__row {
  // margin-bottom: 10px;

  button.timer {
    color: kt-base-color(grey, 1);
    :hover {
      @include kt-transition();
      color: kt-base-color(grey, 2);
    }
  }

  label {
    span {
      padding-left: 10px;
    }
    width: $lhcolwidth;
    font-weight: 500;
  }
  .col-1 {
    width: 100px !important;
  }
  .hoursWrapper {
    display: flex;
    width: 65%;
    span {
      flex-grow: 1;
      text-align: center;
      span {
        width: 90px;
      }
    }
  }
}
.timelogged-modal-detail {
  .timelogged-action-descs-container {
    position: relative;
    .timelogged-action-descs-textarea {
      box-sizing: border-box;
      padding-bottom: 80px;
    }
    .timelogged-action-descs {
      position: absolute;
      bottom: 0;
      padding: 0.3rem;
    }
  }
}

@include kt-tablet-and-mobile {
  .kt-subheader .btn.timesheet {
    i {
      font-size: 1.8rem !important;
      padding-right: 0;
    }
  }

  .timesheet {
    .padded {
      padding-left: 0;
    }
    &.kt-portlet--sticky {
      .kt-portlet__head {
        margin-left: 175px;
      }
    }
    .timesheet__headerrow {
      padding-left: 160px;
    }

    .timesheet__row {
      label {
        width: 160px;
        display: block;
      }
    }
  }
}

@include kt-mobile {
  .timesheet {
    .grid-wrapper {
      display: grid;
      grid-template-columns: repeat(7, minmax(50px, 70px));
      grid-template-rows: 50px;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      justify-items: stretch;
      align-items: stretch;
    }
    .grid-row {
      grid-row: 1;
      &.grid-row--block span {
        display: block;
      }

      span.grid-row--button {
        width: 40px;
        font-size: 0.8rem;
      }

      // &:nth-child(6),
      // &:nth-child(7) {
      //   background-color: kt-base-color(grey, 1);
      // }
    }
  }
}

.recharts-responsive-container {
  margin: 0 auto;
}

.chart-tooltip {
  font-size: 0.95rem;
  background-color: white;
  padding: 0.5rem;
  .chart-tooltip__details {
    .chart-tooltip__title {
      font-weight: 600;
    }
    .chart-tooltip__item-details {
      display: block;
    }
  }
}
.slider {
  cursor: none;
  caret-color: transparent;
}
