@font-face {
  font-family: "Metric";
  src: url("./metric-web-light.woff2") format("woff2"),
    url("./metric-web-light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Metric";
  src: url("./metric-web-medium.woff2") format("woff2"),
    url("./metric-web-medium.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
